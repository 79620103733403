import  React,{useEffect, useState, useRef} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Card, Container, Col, Row, Modal } from 'react-bootstrap';
import {store} from "../components/Firebase/firebase-config"
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink} from '@fortawesome/free-solid-svg-icons'
import {faInstagram, faTelegram, faWhatsapp, faFacebook, faTwitter, faLinkedin, faPinterest, faTiktok} from '@fortawesome/free-brands-svg-icons'


const NotFoundPage = () => {

  const [socialMediaList, setSocialMediaList] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState("");
  const [post, setPost] = useState("");
  const [phone, setPhone] = useState('')
  const [picture, setPicture] = useState('');
  const [show, setShow] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [url, setUrl] = useState('');
  const [loading, setloading] = useState(false);
  // useRef
  const txtCopy = useRef();

  

  useEffect(() => {
    const isBrowser = () => typeof window !== "undefined";
    if(isBrowser){
      readBusinessCard();
      readSocialMedia();     
      setUrl(`${window.location.href}`);
      
    }    
  }, [])


  function View(){
    if(loading){
      return(
        <>
        <Seo title="Tarjeta de presentación" />
          <Container>
            <ModalPrueba />
            <Row>
              <Col sm={12} md={12} lg={12}>
                
                <Card className="text-center shadow mt-2" style={{"height": "700px"}}>
                  <Card.Body className="mt-5">
                      <div className="text-center">
                          <img 
                              className="justify-content-center img-center"
                              src={picture}
                              alt=""
                              style={{
                                  "borderRadius": "100px",
                                  "width": "150px",
                                  "height": "150px",
                                  "border": "1px solid #5C5C5C"
                              }} 
                          />
                      </div>
                      <h3 className="text-center">{name}</h3>
                      <h4 
                        className="text-center"
                        style={{"position":"relative", "bottom":"15px"}}
                      >{post}</h4>
                      <p className="text-center">{description}</p>
                      <div className="text-center">
                          <a 
                            href={`tel:${phone}`}
                            className="btn btn-primary btn-sm m-1"
                            style={{"borderRadius":"25px", "backgroundColor": "#a854ad", "color": "white", "border": "1px solid #a854ad"}}
                            
                            >Guardar</a>
                          <button 
                            className="btn btn-secondary btn-sm m-1"
                            style={{"borderRadius":"25px", "border": "1px solid #5C5C5C", "backgroundColor": "white", "color": "#5C5C5C"}}
                            onClick={()=>{handleShow()}}
                            >Compartir</button>
                      </div>
                      <div className="text-center ">
                      
                          {
                              socialMediaList.map((item, i)=>{
                                      if(item.type === "Instagram"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faInstagram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Telegram"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTelegram} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Whatsapp"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faWhatsapp} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Facebook"){ 
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faFacebook} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Twitter"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTwitter} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Linkedin"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faLinkedin} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Pinterest"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faPinterest} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                      if(item.type === "Tiktok"){
                                          return(
                                              <>
                                                  <a href={item.url}><FontAwesomeIcon className="m-1 mt-5" icon={faTiktok} style={{"fontSize": "25px", "color":"gray"}}/></a>
                                              </>
                                          )
                                      }
                                  }
                              )
                          }
                      </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container> 
          {/* sucio method */}

      </>
      )
    }else{
      return(
        <h3 className="text-center">Cargando...</h3>
      )
    }
  }


  function ModalPrueba(){
    return(
      <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header >
            <Modal.Title>Comparte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="text-center">
                    
                    <input type="hidden" id="textCopy" value="Joder hermano"/>
                    <a 
                      href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} 
                      className="btn btn-sm  m-1"
                      style={{"borderRadius": "25px", "backgroundColor": "#3b5998"}}
                    >
                      <FontAwesomeIcon icon={faFacebook} style={{"color":"white"}}/>
                    </a>
                    <a 
                      href={`https://api.whatsapp.com/send?text=${url}`} 
                      className="btn btn-sm  m-1"
                      style={{"borderRadius": "25px", "backgroundColor": "#25D366"}}
                    >
                      <FontAwesomeIcon icon={faWhatsapp} style={{ "color":"white"}}/>
                    </a>
                    <a 
                      href={`https://twitter.com/intent/tweet?text=[text]&url=${url}&hashtags=hashtag`}
                      className="btn btn-sm  m-1"
                      style={{"borderRadius": "25px", "backgroundColor": "#1DA1F2"}}
                    >
                      <FontAwesomeIcon icon={faTwitter} style={{"color":"white"}}/>
                    </a>
                    <a 
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                      className="btn btn-sm m-1"
                      style={{"borderRadius": "25px", "backgroundColor": "#0077b5"}}
                    >
                      <FontAwesomeIcon icon={faLinkedin} style={{"color":"white"}}/>
                    </a>
                    <button 
                      onClick={()=>{
                        copyText();
                      }}
                      className="btn btn-sm m-1"
                      style={{"borderRadius": "25px", "backgroundColor": "gray"}}
                    >
                      <FontAwesomeIcon icon={faLink} style={{"color":"white"}}/>
                    </button>
                    <br />
                    <label for="txtCopy">Enlace a copiar</label>
                    <input ref={txtCopy} id="txtCopy" value={`${url}`}  class="form-control"></input>
                  </div>
                </Col>
              </Row>
            </Container>
        </Modal.Body>
      </Modal>
      </>
    )
  }

  const copyText = ()=>{
    txtCopy.current.select();
    document.execCommand("copy");
  };
  const readBusinessCard = async ()=>{
    const businessCard =  store.collection('businessCard');
    const query =  businessCard.where("path", "==", `${window.location.pathname}`, true).get()
    .then(async (querySnapshot) => {
        if(querySnapshot.size ==0){
            console.log("No font data")
        }else{
            querySnapshot.forEach(async(doc) => {
              setName(doc.data().name);
              setDescription(doc.data().description);
              setPost(doc.data().post);
              setPicture(doc.data().picture); 
              setPhone(doc.data().phone);
            });
        }
        setloading(true);
    })
    .catch((err)=>{
        console.log(err)
    });
  }

  const readSocialMedia = async ()=>{
    const socialMedia =  store.collection('socialMedia');
    const query =  socialMedia.where("path", "==", `${window.location.pathname}`, true).get()
    .then(async (querySnapshot) => {
        if(querySnapshot.size ==0){
           
        }else{
            var array = [];
            querySnapshot.forEach(async(doc) => {
                var json = {
                    type: doc.data().type,
                    url: doc.data().url,
                    id: doc.id,
                    user: doc.data().user
                };
                array.push(json); 
                console.log(array);
            });
            setSocialMediaList(array);
        }
    })
    .catch((err)=>{
        console.log(err)
    });

  }


  return(
    <View /> 
  )
}

export default NotFoundPage
